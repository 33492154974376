import { template as template_91d1f3247efa4a23925eefaf36bbafa5 } from "@ember/template-compiler";
const SidebarSectionMessage = template_91d1f3247efa4a23925eefaf36bbafa5(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
