import { template as template_ac813a2a156e42438661887e6395a435 } from "@ember/template-compiler";
const WelcomeHeader = template_ac813a2a156e42438661887e6395a435(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
