import { template as template_b58c450621e74a7b94b0c02f1dc36feb } from "@ember/template-compiler";
const FKLabel = template_b58c450621e74a7b94b0c02f1dc36feb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
