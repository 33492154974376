import { template as template_f081ff6018514a58ab6569bea7cf4911 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_f081ff6018514a58ab6569bea7cf4911(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
